import React from 'react';

import { About, Seo, SeoHidden } from 'components';

export default () => (
  <>
    <Seo title="Услуги" description="Услуги digital-агентства Nimax: стратегия и digital. Помогаем стартовать и меняться. Объединяем отраслевой опыт клиента и нашу digital-экспертизу." />
    <SeoHidden>
      <h1>Услуги</h1>
    </SeoHidden>
    <About />
  </>
);
